import React from 'react';
import { Button } from '@mui/material'; // Import Material-UI Button

interface ChatFormProps {
    message: string;
    setMessage: (e: React.SetStateAction<string>) => void;
    handleSubmit: (e: React.FormEvent) => Promise<void>;
    waitingForAnswer: boolean;
}

export function ChatForm({
    message,
    setMessage,
    handleSubmit,
    waitingForAnswer,
}: ChatFormProps) {
    return (
        <form className="h-16 flex border-t border-gray-300 p-2" onSubmit={handleSubmit}>
            <textarea
                className="flex-grow px-4 py-2 text-sm bg-white border border-gray-200 rounded"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={waitingForAnswer}
            />
            <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={waitingForAnswer}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                Send
            </Button>
        </form>
    );
}