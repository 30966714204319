export enum Path {
  HOME = '/',
  AICHAT = '/AIChat',
  MEDIACLOUD = '/mediaCloud',
  MEDIACLOUD_ALBUM_PARAM = '/mediaCloud/album/:albumName',
  MONEY = '/money',
  USER = '/user',
  USER_PROFILE = '/user/profile',
  USER_PROFILE_PARAM = '/user/profile/:id',
  ABOUT = '/about',
  ANY_OTHER = '*',
}
