import {
  FindAllArgs,
  ErrorProps,
} from '@wolftechapp/wolftech-custom-mui-components';
import request from '../utils/request';
import { WalletModel, MoneyModel, CreateMoneyModel } from '../models';

export const GetMoneiesQuery = `
        query GetMoneies($findAllArgs: FindAllArgs!) {
            getMoneies(findAllArgs: $findAllArgs) {
              result {
                id
                type
                category
                note
                amount
                currency
                latitude
                longitude
                user {
                  id
                  loginName
                  email
                }
                wallet {
                    id
                    name
                }
                createdAt
                updatedAt
              }
              totalCount
            }
        }
    `;

class MoneyService {
  private readonly BASE_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_GRAPHQL_URL_POSTFIX}`;

  getWallets = async (findAllArgs: FindAllArgs) => {
    const graphqlQuery = {
      query: `
            query GetWallets($findAllArgs: FindAllArgs!) {
                getWallets(findAllArgs: $findAllArgs) {
                  result {
                    id
                    name
                    slug
                    currency
                    exchangePrice
                    userWallet {
                        user {
                            id
                            loginName
                        }
                    }
                  }
                  totalCount
                }
            }
        `,
      variables: {
        findAllArgs,
      },
    };

    return request<{
      getWallets: { result: WalletModel[]; errors?: ErrorProps[] };
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  createMoney = async (createMoneyProps: CreateMoneyModel) => {
    const graphqlQuery = {
      query: `
            mutation CreateMoney($type: Float!, $category: String!, $note: String, $amount: Float!, $currency: String!, $latitude: Float!, $longitude: Float!, $walletId: String!, $userId: String!) {
                createMoney(createMoneyInput: {
                  type: $type
                  category: $category
                  note: $note
                  amount: $amount
                  currency: $currency
                  latitude: $latitude
                  longitude: $longitude
                  walletId: $walletId
                  userId: $userId
                }) {
                  id
                  type
                  category
                  note
                  amount
                  currency
                  latitude
                  longitude
                  user {
                      id
                  }
                  wallet {
                      id
                  }
                  createdAt
                  updatedAt
                }
            }
        `,
      variables: {
        ...createMoneyProps,
      },
    };

    return request<{ result: Partial<MoneyModel>; errors?: ErrorProps[] }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };

  getMoneies = async (findAllArgs: FindAllArgs) => {
    return request<{
      getMoneies: { result: MoneyModel[]; errors?: ErrorProps[] };
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        query: GetMoneiesQuery,
        variables: {
          findAllArgs,
        },
      },
    });
  };

  getMoney = async (id: string) => {
    const graphqlQuery = {
      query: `
            query GetMoney($findAlbumInput: FindAlbumInput!) {
                getMoney(findAlbumInput: $findAlbumInput) {
                  id
                }
            }
        `,
      variables: {
        findAlbumInput: { id },
      },
    };

    const response = await request<{
      getMoney: MoneyModel;
      errors?: ErrorProps[];
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });

    return response;
  };

  removeMoneies = async (moneyIds: string[]) => {
    const graphqlQuery = {
      query: `
            mutation RemoveMoneies($ids: [String!]!) {
              removeMoneies(ids: $ids) {
                moneies {
                  id
                }
                result
              }
            }
        `,
      variables: {
        ids: moneyIds,
      },
    };

    return request<{
      removeAlbum: { albums: MoneyModel[]; result: string };
      errors?: ErrorProps[];
    }>({
      url: `${this.BASE_URL}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: graphqlQuery,
    });
  };
}
export const moneyService = new MoneyService();
