import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Theme } from '@mui/material/styles';
import i18n from 'i18next';
import { huHU, enUS, GridInitialState } from '@mui/x-data-grid';
import {
  DataGrid as WolftechDataGrid,
  DataGridProps,
  FindAllArgs,
  DatagridFilterModelOperatorTypeEnum,
} from '@wolftechapp/wolftech-custom-mui-components';
import { isNil, isNumber, isFunction, isEmpty } from 'lodash';
import { updateUser } from '../../store/auth';

export const DataGrid = ({
  gridIdentifier,
  dataLoader,
  ...props
}: DataGridProps) => {
  const { authLoading } = useSelector((state: ApplicationState) => state.auth);
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const parsedDatagridStates = !isNil(user?.datagridState) ? JSON.parse(user?.datagridState || '') : ''

  const dispatch = useDispatch<AppDispatch>();

  let langKey = enUS;
  switch (i18n.language) {
    case 'hu':
      langKey = huHU;
      break;
    case 'en':
      langKey = enUS;
      break;
    default:
      break;
  }

  const createGqlParams = (params: GridInitialState) => {
    const order = {
      orderBy:
        !isNil(params?.sorting?.sortModel) && !isEmpty(params?.sorting?.sortModel)
          ? params?.sorting?.sortModel[0].field
          : 'id',
      orderDirection:
        !isNil(params?.sorting?.sortModel) && !isEmpty(params?.sorting?.sortModel)
          ? params?.sorting?.sortModel[0].sort
          : 'asc',
    };
    let andWhere: FindAllArgs['andWhere'] = [];
    let orWhere: FindAllArgs['orWhere'] = [];
    if (!isNil(params?.filter?.filterModel)) {
      const conditions = params?.filter?.filterModel.items.map((item) => {
        return {
          by: item.field,
          for: !isNil(item.value) ? item.value : '',
          operator: item.operator as DatagridFilterModelOperatorTypeEnum,
        };
      });
      andWhere = params?.filter?.filterModel.logicOperator === 'and' ? conditions : [];
      orWhere = params?.filter?.filterModel.logicOperator === 'or' ? conditions : [];
    }

    const page = params?.pagination?.paginationModel?.page ?? 0;
    const pageSize = params?.pagination?.paginationModel?.pageSize ?? 20;

    const skip = page * pageSize;
    const take = pageSize;

    const gqlParams = {
      paginate: {
        ...order,
        skip,
        take
      },
      andWhere,
      orWhere,
    };

    return dataLoader && dataLoader(gqlParams);
  };

  return (
    <WolftechDataGrid
      consoleLogData={false}
      gridIdentifier={gridIdentifier}
      forcedLoadingState={authLoading}
      initialTableState={parsedDatagridStates[gridIdentifier] ?? ""}
      dataLoader={createGqlParams}
      localeText={langKey.components.MuiDataGrid.defaultProps.localeText}
      restoreState={parsedDatagridStates}
      getStateForSave={(gridState) => {
        console.log({ gridState });
        if (!isNil(user)) {
          const { datagridState: allGridState } = user;
          const mergedGridState = {
            ...JSON.parse(!isNil(allGridState) ? allGridState || '' : '{}'),
            ...gridState,
          };
          dispatch(
            updateUser(user.id, {
              datagridState: JSON.stringify(mergedGridState),
            })
          );
        }
      }}
      dataGridHeightCorrection={
        isNumber(props.dataGridHeightCorrection)
          ? props.dataGridHeightCorrection
          : (theme: Theme) => {
            const extraHeightDifference =
              (isFunction(props.dataGridHeightCorrection) &&
                props.dataGridHeightCorrection(theme)) ||
              0;

            return (
              theme.mixins.pageHeader.appBar.height +
              theme.mixins.page.paddingTop +
              theme.mixins.page.paddingBottom +
              extraHeightDifference
            );
          }
      }
      {...props}
    />
  );
};
