import { useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';

interface ChatHistoryProps {
    conversation: {
        role: string;
        content: string;
    }[];
    waitingForAnswer: boolean;
}

export function ChatHistory({ conversation, waitingForAnswer }: ChatHistoryProps) {
    const lastMessageRef = useRef<HTMLDivElement>(null);

    // Scroll to the last message when the conversation updates
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation, waitingForAnswer]);

    const renderContent = (content: string, role: string) => {
        const codeRegex = /```(\w*)?\n([\s\S]*?)(?=```|$)/g; // Matches code blocks, even if unclosed
        const parts: (string | { language: string; code: string })[] = [];
        let match;
        let lastIndex = 0;

        while ((match = codeRegex.exec(content)) !== null) {
            if (lastIndex < match.index) {
                parts.push(content.slice(lastIndex, match.index));
            }
            const language = match[1] || 'text';
            const code = match[2].trim();
            parts.push({ language, code });

            lastIndex = codeRegex.lastIndex;
        }

        if (lastIndex < content.length) {
            parts.push(content.slice(lastIndex));
        }

        return parts.map((part, index) => {
            if (typeof part === 'string') {
                // Render plain text with appropriate background color based on role
                const bgColor = role === 'user' ? 'bg-blue-500' : 'bg-green-300';
                const textColor = role === 'user' ? 'text-white' : 'text-black';
                return (
                    <div
                        key={index}
                        className={`w-full p-3 rounded-lg text-sm leading-relaxed ${bgColor} ${textColor} whitespace-pre-wrap`}
                    >
                        {part.trim()}
                    </div>
                );
            } else {
                // Render code block
                return (
                    <pre
                        key={index}
                        className="w-full bg-gray-100 border border-gray-300 rounded-md p-3 overflow-x-auto whitespace-pre-wrap text-sm font-mono mb-3"
                    >
                        <code>{part.code}</code>
                    </pre>
                );
            }
        });
    };

    return (
        <div className="flex-1 overflow-y-auto p-4 bg-white border-b border-gray-300">
            {conversation.map((msg, index) => (
                <div
                    key={index}
                    className={`flex items-start my-2 ${msg.role === 'user' ? 'justify-end' : 'justify-start'
                        }`}
                >
                    {/* <div className="font-bold mr-2">{msg.role}:</div> */}
                    <div className="flex flex-col">{renderContent(msg.content, msg.role)}</div>
                    {index === conversation.length - 1 && (
                        <div ref={lastMessageRef}></div>
                    )}
                </div>
            ))}
            {waitingForAnswer && (
                <div className="flex justify-center items-center mt-4">
                    <CircularProgress size={24} />
                </div>
            )}
        </div>
    );
}