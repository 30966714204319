import { useState, useEffect, useCallback, useMemo } from 'react';

type GraphQLRequestOptions = {
  query: string;
  variables?: Record<string, any>;
};

type UseRequestProps = {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'; // HTTP method
  headers?: Record<string, string>; // Optional headers
  body?: any; // Optional body for POST/PUT
  credentials?: 'omit' | 'same-origin' | 'include'; // Optional credentials
  graphql?: GraphQLRequestOptions; // Include if this is a GraphQL request
  autoFetch?: boolean; // Automatically fetch on mount
};

type UseRequestReturn<T> = {
  data: T | null;
  error: string | null;
  loading: boolean;
  fetchData: (dynamicOverrides?: {
    body?: any;
    headers?: Record<string, string>;
    credentials?: 'omit' | 'same-origin' | 'include';
    graphql?: Partial<GraphQLRequestOptions>;
  }) => Promise<T | null>;
};

export const useRequest = <T>({
  url,
  method = 'GET',
  headers = {},
  body,
  credentials = 'include',
  graphql,
  autoFetch = false,
}: UseRequestProps): UseRequestReturn<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Memoize headers, graphql, and body to ensure stability
  const memoizedHeaders = useMemo(() => headers, [JSON.stringify(headers)]);
  const memoizedGraphQL = useMemo(() => graphql, [JSON.stringify(graphql)]);
  const memoizedBody = useMemo(() => body, [JSON.stringify(body)]);

  const fetchData = useCallback(
    async (
      dynamicOverrides: {
        body?: any;
        headers?: Record<string, string>;
        credentials?: 'omit' | 'same-origin' | 'include';
        graphql?: Partial<GraphQLRequestOptions>;
      } = {}
    ): Promise<T | null> => {
      setLoading(true);
      setError(null);

      try {
        const mergedHeaders = {
          'Content-Type': memoizedGraphQL
            ? 'application/json'
            : 'application/json',
          ...memoizedHeaders,
          ...dynamicOverrides.headers,
        };

        const finalGraphQL = {
          query: dynamicOverrides.graphql?.query ?? memoizedGraphQL?.query,
          variables:
            dynamicOverrides.graphql?.variables ?? memoizedGraphQL?.variables,
        };

        const finalMethod =
          memoizedGraphQL || dynamicOverrides.graphql ? 'POST' : method;

        let requestBody;
        switch (true) {
          case !!finalGraphQL.query:
            requestBody = JSON.stringify(finalGraphQL);
            break;

          case !!dynamicOverrides.body:
            requestBody =
              typeof dynamicOverrides.body === 'string'
                ? dynamicOverrides.body
                : JSON.stringify(dynamicOverrides.body);
            break;

          case !!memoizedBody:
            requestBody =
              typeof memoizedBody === 'string'
                ? memoizedBody
                : JSON.stringify(memoizedBody);
            break;

          default:
            requestBody = undefined;
        }

        const response = await fetch(url, {
          method: finalMethod,
          headers: mergedHeaders,
          body: requestBody,
          credentials: dynamicOverrides.credentials ?? credentials,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        let responseData = await response.json();

        // Handle GraphQL-specific errors
        if (finalGraphQL.query && responseData.errors) {
          throw new Error(responseData.errors[0]?.message || 'GraphQL Error');
        }

        // Extract GraphQL data by first field name
        if (finalGraphQL.query && responseData.data) {
          const match = finalGraphQL.query.match(/{\s*(\w+)/);
          const firstFieldName = match ? match[1] : null;

          if (firstFieldName && responseData.data[firstFieldName]) {
            responseData = responseData.data[firstFieldName]; // Extract the specific first field's data
          } else {
            responseData = responseData.data; // Fallback to full data
          }
        }

        setData(responseData);

        return responseData;
      } catch (err: any) {
        setError(err.message || 'An error occurred');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [url, method, memoizedHeaders, memoizedBody, credentials, memoizedGraphQL]
  );

  useEffect(() => {
    if (autoFetch) {
      fetchData().catch(console.error);
    }
  }, [fetchData, autoFetch]);

  return { data, error, loading, fetchData };
};
