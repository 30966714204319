import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  generateColumns,
  DataGridColumnType,
  FindAllArgs,
  DatagridFilterModelOperatorTypeEnum,
} from '@wolftechapp/wolftech-custom-mui-components';
import { CreateMoneyModal } from './CreateMoneyModal/CreateMoneyModal';
import {
  Add,
} from '@mui/icons-material';
import { Button, InputLabel } from '@mui/material';
import { MoneyModel, CreateMoneyModel } from '../../models';
import { DataGrid } from '../../components/datagrid/datagrid';
import { GridIdentifiersTypeEnum } from '../../components/datagrid/gridIdentifiers';
import {
  createMoney,
  getWallets,
  // getMoneies,
  removeMoneies,
} from '../../store/money';
import { format as formatDate } from 'date-fns-tz';
import { useRequest } from '../../hooks/useRequest';
import { GetMoneiesQuery } from '../../services';

export function Money() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  /* const { moneiesData, moneiesDataLoading } =
    useSelector((state: ApplicationState) => state.money); */
  /* const { data: moneiesData, error, loading: moneiesDataLoading } = useRequest({
    url: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_GRAPHQL_URL_POSTFIX}`,
    graphql: {
      query: `
          query GetBook($id: ID!) {
            book(id: $id) {
              id
              title
              author
            }
          }
        `,
      variables: { id: '1' }, // Example variables for the query
    },
    autoFetch: true, // Automatically fetch on mount
  }); */
  const { data: moneiesData, error, loading: moneiesDataLoading, fetchData: getMoneies } = useRequest<{ result: MoneyModel[]; totalCount: number }>({
    url: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_GRAPHQL_URL_POSTFIX}`,
    graphql: {
      query: GetMoneiesQuery,
    },
  });
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const [lastGqlParams, setLastGqlParams] = useState<FindAllArgs>();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedMoneies, setSelectedMoneies] = useState<string[]>([]);

  const columns = useMemo(
    () =>
      generateColumns(
        [
          { field: 'id', headerName: 'ID', filterable: true, sortable: true },
          {
            field: 'type',
            headerName: i18n.t('MONEY.DATAGRID_HEADER.TYPE'),
            type: DataGridColumnType.SINGLESELECT,
            valueOptions: [
              { value: "false", label: i18n.t('MONEY.TYPE.EXPENSE') },
              { value: "true", label: i18n.t('MONEY.TYPE.INCOME') },
            ],
            renderCell: ({ row }: { row: MoneyModel }) => (
              <span>{row.type === 0 ? i18n.t('MONEY.TYPE.EXPENSE') : i18n.t('MONEY.TYPE.INCOME')}</span>
            ),
            filterable: true,
            sortable: true,
          },
          {
            field: 'category',
            headerName: i18n.t('MONEY.DATAGRID_HEADER.CATEGORY'),
            type: DataGridColumnType.STRING,
            filterable: true,
            sortable: true,
          },
          {
            field: 'note',
            headerName: i18n.t('MONEY.DATAGRID_HEADER.NOTE'),
            type: DataGridColumnType.STRING,
            filterable: true,
            sortable: true,
          },
          {
            field: 'amount',
            headerName: i18n.t(
              'MONEY.DATAGRID_HEADER.AMOUNT'
            ),
            type: DataGridColumnType.NUMBER,
            filterable: true,
            sortable: true,
          },
          {
            field: 'currency',
            headerName: i18n.t('MONEY.DATAGRID_HEADER.CURRENCY'),
            type: DataGridColumnType.SINGLESELECT,
            valueOptions: [
              { value: "HUF", label: "HUF" },
              { value: "EUR", label: "EUR" },
              { value: "GBP", label: "GBP" },
              { value: "USD", label: "USD" },
            ],
            renderCell: ({ row }: { row: MoneyModel }) => (
              <span>{row.currency}</span>
            ),
            filterable: true,
            sortable: true,
          },
          {
            field: 'wallet.name',
            headerName: i18n.t('MONEY.DATAGRID_HEADER.WALLET'),
            type: DataGridColumnType.STRING,
            valueGetter: ({ row }: { row: MoneyModel }) => row.wallet.name,
            filterable: true,
            sortable: true,
          },
          {
            field: 'createdAt',
            headerName: i18n.t(
              'MONEY.DATAGRID_HEADER.TRANSACTION_DATE'
            ),
            type: DataGridColumnType.DATETIME,
            // valueGetter: ({ row }: { row: MoneyModel }) => new Date(row.createdAt),
            valueFormatter: ((params: any) => {
              const date = new Date(params.value)
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the local time zone
              return formatDate(date, 'PPpp', { timeZone }); // Format the date using date-fns-tz
            }),
            filterable: true,
            sortable: true,
          },
        ],
        {
          enableFiltersForAllColumns: false,
          enableSortingForAllColumns: false,
        }
      ),
    [i18n, lastGqlParams] // eslint-disable-line
  );

  const createMoneyButton = () => {
    return (
      <Fragment key={'createMoney'}>
        <InputLabel htmlFor="create-money-button">
          <Button
            disabled={false}
            color="primary"
            component="span"
            startIcon={<Add />}
            onClick={() => setCreateModalOpen(true)}
          >
            {i18n.t('MONEY.DATAGRID_BUTTON.CREATE_MONEY')}
          </Button>
        </InputLabel>
      </Fragment>
    );
  };

  const createMoneyAction = async (record: CreateMoneyModel) => {
    await dispatch(createMoney(record))
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const removeMoneiesAction = async (moneyIds: string[]) => {
    await dispatch(removeMoneies(moneyIds));
    lastGqlParams && dataLoader(lastGqlParams);
  };

  const dataLoader = async (gqlParams: FindAllArgs) => {
    const requiredCondition = {
      by: 'user.id',
      for: user ? user.id.toString() : '', // "63184ad9-f1a1-4750-8169-f655b3cac683"
      operator: DatagridFilterModelOperatorTypeEnum.STRING_EQUALS,
    };
    dispatch(getWallets({
      andWhere: [{
        by: "userWallet.userId",
        for: user ? user.id.toString() : '',
        operator: DatagridFilterModelOperatorTypeEnum.STRING_EQUALS,
      }]
    }))
    /* dispatch(
      getMoneies({
        ...gqlParams,
        andWhere: gqlParams.andWhere
          ? [...gqlParams.andWhere, { ...requiredCondition }]
          : [{ ...requiredCondition }],
      })
    ); */
    await getMoneies({
      graphql: {
        variables: {
          findAllArgs: {
            ...gqlParams,
            andWhere: gqlParams.andWhere
              ? [...gqlParams.andWhere, { ...requiredCondition }]
              : [{ ...requiredCondition }],
          }
        }
      }
    })
  };

  return (
    <>
      <DataGrid
        gridIdentifier={GridIdentifiersTypeEnum.MONEY_LIST}
        unstable_headerFilters={true}
        forcedLoadingState={moneiesDataLoading}
        dataLoader={(gqlParams: FindAllArgs) => {
          setLastGqlParams(gqlParams);
          dataLoader(gqlParams);
        }}
        paginationAndfilterMode={'server'}
        paginationConfig={{
          enabled: true,
          rowCount: moneiesData?.totalCount || 0,
        }}
        disableRowSelectionOnClick
        checkboxSelection={true}
        getSelectedRows={(rows) => {
          const ids = rows.map((row) => row.id);
          setSelectedMoneies(ids);
        }}
        CRUD={{
          delete: {
            enable: true,
            deleteAction: async (rows) =>
              removeMoneiesAction(rows.map((row: MoneyModel) => row.id)),
          },
        }}
        /* onCellClickAction={(row: AlbumModel) =>
          navigate(`/mediaCloud/album/${row.name}`)
        } */
        toolbarActionButtons={[
          createMoneyButton(),
        ]}
        columns={columns}
        rows={moneiesData?.result || []}
      />
      <CreateMoneyModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={createMoneyAction} />
    </>
  );
}
