import React from 'react';
import { TextField, Autocomplete, Button } from '@mui/material';

interface ConfigurationProps {
    UserAIChats: string[] | null;
    chatName: string;
    setChatName: React.Dispatch<React.SetStateAction<string>>;
    handleDelete: () => Promise<void>
    model: string;
    setModel: React.Dispatch<React.SetStateAction<string>>;
    maxTokens: number;
    setMaxTokens: React.Dispatch<React.SetStateAction<number>>;
    temperature: number;
    setTemperature: React.Dispatch<React.SetStateAction<number>>;
}

export function Configuration({
    UserAIChats = [],
    chatName,
    setChatName,
    handleDelete,
    model,
    setModel,
    maxTokens,
    setMaxTokens,
    temperature,
    setTemperature,
}: ConfigurationProps) {
    return (
        <div className="flex flex-col gap-4 p-4 bg-gray-100 rounded">
            <div className="flex items-center gap-2">
                <Autocomplete
                    className="flex-1"
                    freeSolo
                    options={UserAIChats || []}
                    value={chatName}
                    onInputChange={(event, newInputValue) => setChatName(newInputValue)}
                    onChange={(event, newValue) => setChatName(newValue || '')}
                    renderInput={(params) => (
                        <TextField {...params} label="Chat Name" variant="outlined" />
                    )}
                />
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                    disabled={!chatName || !(UserAIChats || []).includes(chatName)}
                >
                    Delete
                </Button>
            </div>

            <label htmlFor="model">Model:</label>
            <select
                id="model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
            >
                <option value="Llama 3 8B Instruct">Llama 3 8B Instruct</option>
                <option value="GPT-3">GPT-3</option>
                <option value="GPT-4">GPT-4</option>
            </select>

            <label htmlFor="maxTokens">Max Tokens:</label>
            <input
                type="number"
                id="maxTokens"
                value={maxTokens}
                onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
            />

            <label htmlFor="temperature">Temperature:</label>
            <input
                type="number"
                id="temperature"
                value={temperature}
                onChange={(e) => setTemperature(parseFloat(e.target.value))}
                step="0.1"
                min="0"
                max="2"
            />
        </div>
    );
}